:root {
  --blue: #32bbff;
  --nav-clr: "black";
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Merriweather Sans", sans-serif;
}

/* Preventing website refresh on swipe down */
html,
body {
  overscroll-behavior: none;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

a,
a:active {
  color: black;
  text-decoration: none;
  transition: all 0.5s;
}

/* Scrollbar */

::-webkit-scrollbar {
  display: none;
}

/* Wrapper */
.wrapper {
  width: max(25rem, 60vw);
  margin-inline: auto;
  height: 100%;
  /* overflow: scroll; */
}
